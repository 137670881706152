<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-row>
                <el-card
                    class="ma-b"
                    size="small"
                    shadow="never"
                >
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                prop="doctorDepartmentId"
                                label="医生科室"
                                label-width="12em"
                            >
                                <CascaderPicker
                                    :api-class="selectApi"
                                    v-model="formModel.doctorDepartmentId"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="orgName"
                                label="所属机构"
                                label-width="12em"
                            >
                                <el-input
                                    v-model="formModel.orgName"
                                    placeholder="请输入所属机构"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="fullName"
                                label="姓名"
                                label-width="12em"
                            >
                                <el-input
                                    v-model="formModel.fullName"
                                    placeholder="请输入姓名"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="avatar"
                                label="照片"
                                label-width="12em"
                            >
                                <upload
                                    action="/rs/attachment/uploadFileLibrary"
                                    :limit="1"
                                    v-model="formModel.avatar"
                                    tips=""
                                    validate-md5
                                    :oss-action="$globalVar.appConfig.OSS_ACTION"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="labelName"
                                label="标签"
                                label-width="12em"
                            >
                                <el-input
                                    v-model="formModel.labelName"
                                    placeholder="请输入标签"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="idCardNo"
                                label="身份证号"
                                label-width="12em"
                            >
                                <el-input
                                    v-model="formModel.idCardNo"
                                    placeholder="请输入身份证号"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="mobile"
                                label="联系电话"
                                label-width="12em"
                            >
                                <el-input
                                    v-model="formModel.mobile"
                                    placeholder="请输入联系电话"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="sex"
                                label="性别"
                                label-width="12em"
                            >
                                <dictionaries-picker
                                    type="sex"
                                    v-model="formModel.sex"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="birthday"
                                label="出生日期"
                                label-width="12em"
                            >
                                <el-date-picker
                                    v-model="formModel.birthday"
                                    type="date"
                                    placeholder="请选择出生日期"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="graduatedSchool"
                                label="毕业学校"
                                label-width="12em"
                            >
                                <el-input
                                    v-model="formModel.graduatedSchool"
                                    placeholder="请输入毕业学校"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="jobTitle"
                                label="职务级别"
                                label-width="12em"
                            >
                                <el-input
                                    v-model="formModel.jobTitle"
                                    placeholder="请输入职务级别"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="18">
                            <el-form-item
                                prop="personalIntroduction"
                                label="个人介绍"
                                label-width="12em"
                            >
                                <Tinymce
                                    :height="300"
                                    image-action="/rs/attachment/uploadFileLibrary"
                                    v-model="formModel.personalIntroduction"
                                    image-upload-tips=""
                                />
                            </el-form-item>
                            <el-form-item
                                prop="workExperience"
                                label="工作经验"
                                label-width="12em"
                            >
                                <Tinymce
                                    :height="300"
                                    image-action="/rs/attachment/uploadFileLibrary"
                                    v-model="formModel.workExperience"
                                    image-upload-tips=""
                                />
                            </el-form-item>
                            <el-form-item
                                prop="educationalExperience"
                                label="擅长领域"
                                label-width="12em"
                            >
                                <Tinymce
                                    :height="300"
                                    image-action="/rs/attachment/uploadFileLibrary"
                                    v-model="formModel.educationalExperience"
                                    image-upload-tips=""
                                />
                            </el-form-item>
                            <el-form-item
                                prop="relevantQualifications"
                                label="相关资质"
                                label-width="12em"
                            >
                                <Tinymce
                                    :height="300"
                                    image-action="/rs/attachment/uploadFileLibrary"
                                    v-model="formModel.relevantQualifications"
                                    image-upload-tips=""
                                />
                            </el-form-item>
                            <!--                    <el-form-item
                        prop="attachment"
                        label="附件"
                        label-width="12em"
                    >
                        <upload
                            :validate-md5="true"
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="10"
                            v-model="formModel.attachment"
                            tips=""
                        />
                    </el-form-item>-->
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                prop="reservationPrice"
                                label="预约价格"
                                label-width="12em"
                            >
                                <el-input-number
                                    controls-position="right"
                                    v-model="formModel.reservationPrice"
                                    :precision="2"
                                    :min="0"
                                    placeholder="请输入"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="doctorStatus"
                                label="医生状态"
                                label-width="12em"
                            >
                                <dictionaries-picker
                                    type="doctorStatus"
                                    v-model="formModel.doctorStatus"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="isRecommend"
                                label="是否推荐"
                                label-width="12em"
                            >
                                <el-switch
                                    v-model="formModel.isRecommend"
                                    :active-value="true"
                                    :inactive-value="false"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>
                <el-card
                    class="ma-b"
                    size="small"
                    shadow="never"
                >

                    <el-col :span="24">

                        <el-col :span="24">

                            <el-form-item
                                prop="materialsList"
                                label="医生视频"
                                label-width="12em"
                            >
                                <div class="ma-b">
                                    <el-button-group>
                                        <el-button
                                            type="success"
                                            icon="el-icon-plus"
                                            size="small"
                                            @click="onAdd()"
                                        >
                                            新增
                                        </el-button>
                                    </el-button-group>
                                </div>

                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="materialsList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="materialsTitle"
                                        label="视频标题"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="materialsImage"
                                        label="视频图片"
                                        min-width="100"
                                    >
                                        <template
                                            #default="scope">
                                            <ImageList
                                                :data="scope.row.materialsImageUrl"
                                                :item-width="50"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="materialsDesc"
                                        label="视频描述"
                                        min-width="120"
                                    />
                                    <el-table-column
                                        prop="materialsValue"
                                        label="视频文件"
                                        min-width="100"
                                    >
                                        <template
                                            #default="scope">
                                            <ImageList
                                                :data="scope.row.materialsValueUrl"
                                                :item-width="50"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        fixed="right"
                                        label="操作"
                                        width="100"
                                    >
                                        <template slot-scope="scope2">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="onEdit(scope2.row)"
                                            >
                                                编辑
                                            </el-button>
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="onDelete(scope2.row.id)"
                                            >
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>

                        </el-col>
                    </el-col>

                </el-card>

                <el-card
                    class="ma-b"
                    size="small"
                    shadow="never"
                >
                    <el-row>
                        <el-col :span="18">
                            <el-form-item
                                prop="doctorDesc"
                                label="医生描述"
                                label-width="12em"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="formModel.doctorDesc"
                                    placeholder="请输入医生描述"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="18">
                            <el-form-item
                                prop="remarks"
                                label="备注"
                                label-width="12em"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="formModel.remarks"
                                    placeholder="请输入备注"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item
                                label-width="12em"
                            >
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="onConfirm()"
                                >
                                    保 存
                                </el-button>
                                <el-button
                                    size="small"
                                    @click="$router.back()"
                                >
                                    返 回
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>
            </el-row>
        </el-form>
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="1000px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm2"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="materialsTitle"
                    label="视频标题"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog.formModel.materialsTitle"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="materialsImage"
                    label="视频图片"
                    label-width="9em"
                >
                    <upload
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog.formModel.materialsImage"
                        tips=""
                        validate-md5
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                    />
                </el-form-item>
                <el-form-item
                    prop="materialsDesc"
                    label="视频描述"
                    label-width="9em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.materialsDesc"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="materialsValue"
                    label="视频文件"
                    label-width="9em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog.formModel.materialsValue"
                        tips="请上传MP4格式文件"
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                        accept="video/*"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'StationEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                avatar: [],
                attachment: [],
                fullName: '',
                labelName: '',
                idCardNo: '',
                mobile: '',
                sex: '',
                birthday: '',
                graduatedSchool: '',
                jobTitle: '',
                personalIntroduction: '',
                workExperience: '',
                educationalExperience: '',
                relevantQualifications: '',
                reservationPrice: undefined,
                doctorStatus: '',
                remarks: '',
                doctorDesc: '',
                doctorDepartmentId: '',
                orgName: '',
                isRecommend: false,
            },
            // 表单校验规则
            formRules: {
                fullName: {
                    required: true,
                    message: '请输入',
                },
                avatar: {
                    type: 'array',
                    required: true,
                    message: '请选择',
                },
                idCardNo: {
                    required: true,
                    message: '请输入',
                },
                mobile: {
                    required: true,
                    message: '请输入',
                },
                sex: {
                    required: true,
                    message: '请输入',
                },
                reservationPrice: {
                    required: true,
                    message: '请选择',
                },
                doctorStatus: {
                    required: true,
                    message: '请选择',
                },
                doctorDepartmentId: {
                    required: true,
                    message: '请选择',
                },
                isRecommend: {
                    required: true,
                    message: '请选择',
                },
            },
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    doctorId: '',
                    materialsTitle: '',
                    materialsDesc: '',
                    materialsValue: [],
                    materialsValueUrl: '',
                    materialsImage: [],
                    materialsImageUrl: '',
                },
                // 表单校验规则
                formRules: {
                    materialsTitle: {
                        required: true,
                        message: '请输入视频标题',
                        trigger: 'blur',
                    },
                    // materialsValue: {
                    //     type: 'array',
                    //     required: true,
                    //     message: '请上传视频文件',
                    //     trigger: 'blur',
                    // },
                },
            },
            materialsList: [],
            regionApi: this.$api.Rs.Region,
            selectApi: this.$api.Pe.DoctorDepartment,
            fileBasePath: '',
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                const materialsListToSave = this.restoreMaterialsValue(this.materialsList);
                this.$api.Pe.Doctor.save({
                    ...this.formModel,
                    materialsJson: JSON.stringify(materialsListToSave),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initDetail() {
            this.$api.Pe.Doctor.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.materialsList = res.materialsList.map(materials =>
                    this.processMaterials(materials, res.fileBasePath),
                );

                this.fileBasePath = res.fileBasePath;
                this.$utils.formModelMerge(this.formModel, res.doctor);
            });
        },
        processMaterials(materials, fileBasePath) {
            const materialsValue = materials.materialsValue;
            if (!materialsValue) {
                materials.materialsValue = [];
            } else {
                materials.materialsValue = [
                    {
                        name: materials.materialsValue,
                        address: fileBasePath + materials.materialsValue,
                    },
                ];
            }
            const materialsImage = materials.materialsImage;
            if (!materialsImage) {
                materials.materialsImage = [];
            } else {
                materials.materialsImage = [
                    {
                        name: materials.materialsImage,
                        address: fileBasePath + materials.materialsImage,
                    },
                ];
            }
            return {
                ...materials,
            };
        },
        restoreMaterialsValue(materialsList) {
            return materialsList.map(materials => {
                if (materials.materialsValue && Array.isArray(materials.materialsValue)) {
                    materials.materialsValue = materials.materialsValue.map(item => item.name).join(',');
                }
                if (materials.materialsImage && Array.isArray(materials.materialsImage)) {
                    materials.materialsImage = materials.materialsImage.map(item => item.name).join(',');
                }

                return materials;
            });
        },
        processMaterialUrls(materials) {
            materials.materialsValueUrl = this.fileBasePath + materials.materialsValue;
            materials.materialsImageUrl = this.fileBasePath + materials.materialsImage;
            return {
                ...materials,
            };
        },
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm2 } = this.$refs;
                addForm2.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
                formModel.doctor_id = this.formModel.id;
            });
        },
        onEdit(materials) {
            // 将选中的课程数据加载到对话框表单中
            this.addDialog.formModel = this.processMaterialUrls({ ...materials });
            this.addDialog.isVisible = true; // 显示对话框
        },
        onDelete(id) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.materialsList = this.materialsList.filter(item => item.id !== id);
                this.$message({
                    message: '视频已成功删除',
                    type: 'success',
                });
            });
        },
        onAddDialogConfirm() {
            const { addForm2 } = this.$refs;
            addForm2.validate().then(() => {
                const materialsIndex = this.materialsList.findIndex(
                    materials => materials.id === this.addDialog.formModel.id,
                );
                const processedMaterials = this.processMaterialUrls(this.addDialog.formModel);

                if (materialsIndex !== -1) {
                    this.materialsList.splice(materialsIndex, 1, processedMaterials);
                } else {
                    this.materialsList.push(processedMaterials);
                }
                this.addDialog.isVisible = false; // 关闭对话框
                this.$message({
                    message: '视频已成功更新',
                    type: 'success',
                });
            });
        },
        onAddDialogCancel() {
            const { addForm2 } = this.$refs;
            addForm2.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initDetail();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
